<template>
  <footer class="footer">
    <div class="wrapper">
      <div class="left column">
        <router-link
          to="/"
          class="logo"
        >
          <img
            src="./../assets/img/logo.svg"
            class="img"
          >
        </router-link>
        <div class="desc">
          {{ footerRequisites }}
        </div>
      </div>
      <div class="column center">
        <ul class="nav">
          <li class="nav__item">
            <router-link
              class="desc"
              to="/"
            >
              <b>{{ $t('Home') }}</b>
            </router-link>
          </li>
          <li class="nav__item">
            <router-link
              class="desc"
              to="/products/all"
            >
              <b>{{ $t('Store') }}</b>
            </router-link>
          </li>
        </ul>
      </div>
      <div
        v-if="textPageList && textPageList.length"
        class="column center"
      >
        <ul class="nav">
          <li
            v-for="item in textPageList"
            :key="item.id"
            class="nav__item desc"
          >
            <a
              v-if="item"
              @click="goToPage(item.id)"
            >
              <b v-if="item.title">{{ $t(item.title) }}</b>
            </a>
          </li>
        </ul>
      </div>
      <div class="right">
        <div class="desc">
          <b>{{ $t('Support:') }}</b>
        </div>
        <div class="desc">
          {{ footerSupportEmail }}
        </div>
        <div class="list">
          <a
            v-for="link in socials"
            :key="link.image"
            :href="link.link"
            target="_blank"
            class="item"
          >
            <img
              :src="link.img"
              class="img"
            />
          </a>
        </div>
      </div>
    </div>

    <div
      class="wrapper payment-methods"
      v-if="paymentMethodsList && paymentMethodsList.length"
    >
      <ul class="payment-methods-list">
        <li
          v-for="image in allImages"
          :key="image"
          class="image-wrapper"
        >
          <img
            :src="image"
            class="support-icon img"
          />
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'FooterComponent',
  components: {

  },
  props: {
    textPageList: {
      type: Array,
      required: true
    },
    footerRequisites: {
      type: String,
      required: true
    },
    footerSupportEmail: {
      type: String,
      required: true
    },
    paymentMethods: {
      type: [Object, Array],
    }
  },
  data: function () {
    return {
      socials: [],
    }
  },
  computed: {
    paymentMethodsList() {
      if (!this.paymentMethods) return [];
      return this.paymentMethods
    },
    allImages() {
      return this.paymentMethodsList.flatMap(this.getImages);
    }
  },
  watch: {

  },
  mounted() {
    this.getSocials();
  },
  methods: {
    getImages(method) {
      const images = [];
      if (method.code === 'card' || method.title === 'Visa/Mastercard') {
        images.push(require('@/assets/img/methods/visa.svg'), require('@/assets/img/methods/mc_symbol.svg'));
        // images.push(require('@/assets/img/methods/3ds.png'));
      } else if (method.code === 'eps' || method.title === 'EPS') {
        images.push(require('@/assets/img/methods/eps.svg'));
      } else if (method.code === 'trustly' || method.title === 'Trustly') {
        images.push(require('@/assets/img/methods/trustly.svg'));
      } else if (method.code === 'giropay' || method.title === 'GiroPay') {
        images.push(require('@/assets/img/methods/giropay.svg'));
      } else if (method.code === 'blik' || method.title === 'Blik') {
        images.push(require('@/assets/img/methods/blik.svg'));
      } else if (method.code === 'paidby' || method.title === 'Paidby') {
        images.push(require('@/assets/img/methods/paidby.svg'));
      } else if (method.code === 'klarna' || method.title === 'Klarna') {
        images.push(require('@/assets/img/methods/klarna.svg'));
      } else if (method.code === 'sofortuberweisung' || method.title === 'Sofortuberweisung') {
        images.push(require('@/assets/img/methods/sofortuber.svg'));
      } else if (method.code === 'ideal' || method.title === 'iDEAL') {
        images.push(require('@/assets/img/methods/ideal.svg'));
      } else if (method.code === 'bancontact' || method.title === 'Bancontact') {
        images.push(require('@/assets/img/methods/bancontact.svg'));
      }
      return images;
    },
    goToPage(id) {
      this.$emit('goToPage', id)
    },
    getSocials() {
      this.$http.get(process.env.VUE_APP_API + 'social-links')
        .then((res) => {
          this.socials = res.data.payload;
        })
        .catch(() => {
        });
    },
  }
}
</script>
<style scoped lang="scss">
.wrapper.payment-methods {
  margin-top: 20px;
}

.payment-methods-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  // max-width: 200px;

  img {
    max-width: 70px;
    max-height: 35px;
    display: inline-block;
    width: 100%;
  }
}
</style>