<template>
    <div
        v-if="visible"
        class="modal confirmation-modal"
    >
        <div
            class="overlay"
            @click="handleClose"
        />
        <div class="wrapper">
            <div class="container">
                <div class="modal-header">
                    <h2 class="modal-title">{{ title }}</h2>
                    <img
                        src="@/assets/img/close.svg"
                        @click="handleClose"
                        class="close"
                        alt="Close"
                    />
                </div>
                <p
                    class="confirmation-message"
                    v-html="message"
                ></p>
                <div class="button-container">
                    <button
                        class="button confirm"
                        @click="handleConfirm"
                    >{{ confirmText }}</button>
                    <button
                        class="button cancel dark"
                        @click="handleClose"
                    >{{ cancelText }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConfirmationModal',
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        message: {
            type: String,
            required: true
        },
        confirmText: {
            type: String,
            default: 'Confirm'
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        }
    },
    methods: {
        handleClose() {
            this.$emit('close');
        },
        handleConfirm() {
            this.$emit('confirm');
        }
    }
};
</script>

<style lang="scss" scoped>
.page-inside .hero-section .wrapper {
    border: none;
}

.modal.confirmation-modal {
    // font-family: 'Noto Sans', sans-serif;
    color: #fff;

    .wrapper {
        max-width: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container {
        height: auto;
        width: 100%;
        padding: 32px;
        // background: #1c1a24;
        box-shadow: 0px 8px 8px 0px #0000001F;
        display: flex;
        flex-direction: column;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    .modal-title {
        font-size: 24px;
        font-weight: 700;
    }

    .close {
        cursor: pointer;
        filter: invert(100%);
    }

    .confirmation-message {
        line-height: 1.2;
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 24px;
    }

    .button-container {
        display: flex;
        // justify-content: flex-end;
        gap: 16px;
    }

    .button {
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;

        &.confirm {
            // background-color: #64ca80;
            // color: #fff;
        }

        &.cancel {
            // background-color: #3C3A44;
            // color: #fff;
        }
    }
}
</style>