import { createRouter, createWebHistory } from "vue-router";
import HomePage from "./pages/HomePage.vue";
import PageNotFound from "./pages/PageNotFound.vue";
import PaymentSuccessPage from './pages/PaymentSuccessPage.vue'
import PaymentFaliPage from './pages/PaymentFaliPage.vue'
import TextPage from './pages/TextPage.vue'
import ProductListPage from './pages/ProductListPage.vue'
import ProfilePage from './pages/ProfilePage.vue'
import CartPage from './pages/CartPage.vue'
import EmailVerification from "./pages/EmailVerification.vue";
import ProductPage from "./components/ProductPage.vue";
import PasswordReset from "./pages/PasswordReset.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: '/success-payment',
    name: 'PaymentSuccessPage',
    component: PaymentSuccessPage
  },
  {
    path: '/fail-payment',
    name: 'PaymentFaliPage',
    component: PaymentFaliPage
  },
  {
    path: '/pages/:id',
    name: 'TextPage',
    component: TextPage
  },
  {
    path: '/products/:type',
    name: 'ProductListPage',
    component: ProductListPage
  },
  {
    path: '/product/:type/:id',
    name: 'ProductPage',
    component: ProductPage,
    props: true
  },
  {
    path: '/profile/:page',
    name: 'ProfilePage',
    component: ProfilePage
  },
  {
    path: '/verify-email',
    name: 'EmailVerification',
    component: EmailVerification
  },
  {
    path: '/cart',
    name: 'CartPage',
    component: CartPage
  },
  {
    path: "/reset-password",
    name: "PasswordReset",
    component: PasswordReset,
    props: true
  },
  {
    path: "/reset-password",
    name: "PasswordReset",
    component: PasswordReset,
    props: true
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


export default router;
