<template>
    <div class="last-sales-section" v-if="sales && sales.length">
        <div class="wrapper">
            <div class="slider-header">
                <h2 class="slider-title">{{ $t('Last Sales') }}</h2>
            </div>
            <Vue3Marquee :delay="3" :duration="30" :pauseOnHover="true" :clone="true" class="marquee-container">
                <div class="marquee-item" v-for="(sale, index) in sales" :key="index">
                    <ProductCard :item="sale" :currencySymbol="currencySymbol" :last-sale="true"
                        @goToProduct="(item) => handleClick(item)" />
                </div>
            </Vue3Marquee>
        </div>
    </div>
</template>

<script>
import { Vue3Marquee } from 'vue3-marquee'
import ProductCard from './ProductCard.vue'
import { mapGetters } from 'vuex';

export default {
    name: 'LastSales',
    emits: ['goToProduct'],
    props: {
    },
    components: {
        ProductCard,
        Vue3Marquee
    },
    data() {
        return {
            sales: [],
        }
    },
    computed: {
        ...mapGetters('app', ['currencyCode', 'currencySymbol']),
    },
    mounted() {
        this.fetchLastSales()
    },
    watch: {
        currencyCode() {
            this.fetchLastSales()
        }
    },
    methods: {
        handleClick(item) {
            this.$emit('goToProduct', item)
        },
        fetchLastSales() {
            this.$http.get(process.env.VUE_APP_API + 'items/rand-type-items?currency=' + this.currencyCode)
                .then(response => {
                    if (response.status === 200) {
                        this.sales = response.data.payload
                    }
                })
                .catch(error => {
                    console.error('Error fetching last sales:', error)
                })
        },
    }
}
</script>

<style scoped lang="scss">
.last-sales-section {
    margin: 20px 0;

    .slider-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 69px;
        margin-bottom: 40px;
        color: white;
        font-size: 48px;
        font-weight: 700;
    }

    .marquee-container {
        overflow: hidden;
    }

    .marquee-item {
        display: inline-flex;
        margin-right: 16px;
        width: 228px;
    }
}
</style>