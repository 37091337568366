<template>
  <main class="home-page">
    <section class="section hero-section">
      <div class="wrapper">
        <div class="left">
          <div class="title big">
            {{ $t('The dream of CS2 collectors') }}
          </div>
          <div class="desc">
            {{ $t('Your trusted partner for CS2 skins trading') }}
          </div>
          <button v-if="!isAuth" class="button" @click="$emit('openSignUp')">
            {{ $t('Let\'s start') }}
          </button>
          <router-link to="/products/all" v-if="isAuth" class="button">
            {{ $t('Let\'s start') }}
          </router-link>

        </div>
        <div class="right">
          <div class="item item1">
            <img src="./../assets/img/hero1.png" class="img">
            <img src="./../assets/img/hero1Shadow.png" class="shadow">
            <img src="./../assets/img/line.svg" class="line">
            <div class="text">
              <div class="desc blue">
                <b>{{ $t('KARAMBIT') }}</b>
              </div>
              <div class="desc big">
                <b>{{ $t('GAMMA DOPPLER') }}</b>
              </div>
            </div>
          </div>
          <div class="item item2">
            <img src="./../assets/img/hero2.png" class="img">
            <img src="./../assets/img/hero2Shadow.png" class="shadow">
            <img src="./../assets/img/line.svg" class="line">
            <div class="text">
              <div class="desc blue">
                <b>{{ $t('AK-47') }}</b>
              </div>
              <div class="desc big">
                <b>{{ $t('NEON RIDER') }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section features-section">
      <div class="wrapper">
        <div class="item">
          <div class="title">
            100%
          </div>
          <div class="desc">
            {{ $t('Experience peace of mind with our platform\'s 100% secure transaction process') }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            24/7
          </div>
          <div class="desc">
            {{ $t('Our customer support team is available 24/7 to assist with any issues') }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            60k
          </div>
          <div class="desc">
            {{ $t('Join a thriving community of over 60k active users who are passionate about CS2 skins') }}
          </div>
        </div>
      </div>
    </section>
    <section class="section about-section">
      <div class="wrapper">
        <div class="title">
          {{ $t('About us') }}
        </div>
        <div class="item item-main">
          <div class="title small">
            Topnotch trading experience for every CS2 fan
          </div>
        </div>
        <div class="flex">
          <div class="item">
            <div class="desc medium">
              <b>{{ $t('Extensive collection') }}</b>
            </div>
            <div class="desc big blue">
              01
            </div>
          </div>
          <div class="item">
            <div class="desc medium">
              <b>{{ $t('Customer-centric experience') }}</b>
            </div>
            <div class="desc big blue">
              02
            </div>
          </div>
          <div class="item">
            <div class="desc medium">
              <b>{{ $t('Secure and seamless transactions') }}</b>
            </div>
            <div class="desc big blue">
              03
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section home-products-section" v-if="saleProducts && saleProducts.length">
      <div class="wrapper">
        <div class="top">
          <div class="title">
            {{ $t('Hot deals') }}
          </div>
          <router-link to="/products/all" class="desc blue">
            {{ $t('View all') }}
          </router-link>
        </div>
        <Transition>
          <div v-if="saleProducts && saleProducts.length" class="products">
            <div v-for="(item, i) in saleProducts.slice(0, 5)" :key="i" class="item">
              <ProductCard :item="item" :currencySymbol="currencySymbol" :sale="true" @goToProduct="goToProduct" />
            </div>
          </div>
        </Transition>
      </div>
    </section>

    <section class="section last-sales-section">
      <LastSales :currencySymbol="currencySymbol" @goToProduct="goToProduct" />
    </section>

    <section class="section packs-of-random-section">
      <RandomCasesSlider :currencySymbol="currencySymbol" :currencyCode="currencyCode" @goToProduct="goToProduct" />
    </section>

    <section class="section home-products-section new-arrivals-section">
      <div class="wrapper">
        <div class="top">
          <div class="title">
            {{ $t('New arrivals') }}
          </div>
          <router-link to="/products/all" class="desc blue">
            {{ $t('View all') }}
          </router-link>
        </div>
        <Transition>
          <div v-if="newArrivals && newArrivals.length" class="products">
            <div v-for="(item, i) in newArrivals.slice(0, 5)" :key="i" class="item">
              <ProductCard :item="item" :currencySymbol="currencySymbol" @goToProduct="goToProduct" />
            </div>
          </div>
        </Transition>
      </div>
    </section>
    <section class="section payment-methods-section">
      <div class="wrapper">
        <div class="title">
          {{ $t('Variety of supported payment methods') }}
        </div>
        <div class="list">
          <div class="item">
            <img src="./../assets/img/methods/shield.svg" class="img">
            <div class="title small">
              {{ $t('Coming soon...') }}
            </div>
            <div class="desc blue">
              {{ $t('Fast and simple for your perfect experience') }}
            </div>
          </div>
          <div class="item">
            <img src="./../assets/img/methods/mc.svg" class="img">
            <div class="title small">
              {{ $t('Mastercard') }}
            </div>
            <div class="desc blue">
              {{ $t('World leader in digital payments') }}
            </div>
          </div>
          <div class="item">
            <img src="./../assets/img/methods/visa.svg" class="img">
            <div class="title small">
              {{ $t('Visa') }}
            </div>
            <div class="desc blue">
              {{ $t('Accepted in 210+ Countries') }}
            </div>
          </div>
          <div class="item">
            <img src="./../assets/img/methods/shield.svg" class="img">
            <div class="title small">
              {{ $t('Coming soon...') }}
            </div>
            <div class="desc blue">
              {{ $t('Fast and simple for your perfect experience') }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="faq && faq.length" class="section faq-section">
      <div class="wrapper">
        <div class="title">
          {{ $t('Frequently Asked Questions') }}
        </div>
        <div class="accordion">
          <div class="item" v-for="(item, i) in faq" :key="i">
            <div class="panel" @click="togglePanel(i)">
              <div :class="['desc big', { 'blue': isActivePanel(i) }]">{{ item.question }}</div>
              <img src="./../assets/img/arrow-down.svg" :class="['arrow', { 'active': isActivePanel(i) }]" />
            </div>
            <div class="preview" v-if="isActivePanel(i)">
              <div class="desc">{{ item.answer }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { mapActions } from 'vuex'
import ProductCard from '../components/ProductCard.vue'
import RandomCasesSlider from "../components/RandomCasesSlider.vue";
import LastSales from "../components/LastSales.vue";
export default {
  name: 'HomePage',
  components: {
    ProductCard,
    RandomCasesSlider,
    LastSales,
  },
  props: {
    currencySymbol: {
      type: String,
      required: true
    },
    isAuth: {
      type: Boolean,
      required: true
    },
    currencyCode: {
      type: String,
      required: true
    },
  },
  data: function () {
    return {
      saleProducts: [],
      newArrivals: [],
      activePanels: [],
      faq: []
    }
  },
  watch: {
    currencyCode() {
      this.getNewArrivals();
      this.getSale();
    },
  },
  mounted() {
    this.getSale();
    this.getNewArrivals();
    this.getFaq();
    if(this.isAuth) {
      this.fetchActiveDiscount(this.currencyCode);
    }
  },
  methods: {
    ...mapActions('discount', ['fetchActiveDiscount']),
    getFaq() {
      this.$http.get(process.env.VUE_APP_API + 'faq')
        .then((res) => {
          this.faq = res.data.payload
        })
        .catch(() => {

        })
    },
    getNewArrivals() {
      this.$http.get(process.env.VUE_APP_API + 'items/list?new=true&currency=' + this.currencyCode)
        .then((res) => {
          this.newArrivals = res.data.payload;
        })
        .catch(() => {
        })
    },
    getSale() {
      this.$http.get(process.env.VUE_APP_API + 'items/list?sale=true&currency=' + this.currencyCode)
        .then((res) => {
          this.saleProducts = res.data.payload;
        })
        .catch(() => {
        })
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    togglePanel(index) {
      if (this.isActivePanel(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActivePanel(index) {
      return this.activePanels.includes(index)
    },
  }
}
</script>