<template>
  <main class="page-inside page-not-found">
    <div class="section page-not-found-section">
      <div class="title big">
        404
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'PageNotFound',
  components: {
  },
  props: [],
  data: function() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>