<template>
    <div class="modal discount-modal">
        <div class="overlay" @click="handleCloseModal" />
        <div class="wrapper">
            <div class="container">
                <div class="modal-header">
                    <h2 class="modal-title">{{ $t(`Thank you for your purchase!`) }}</h2>
                    <img src="./../assets/img/close.svg" @click="handleCloseModal" class="close" />
                </div>
                <p class="discount-message">
                    As a complimentary gift you have <span class="highlight">{{ discountDuration }}min</span> of
                    <span class="highlight">{{ parseInt(discountPercentage) }}%</span> discount to catch more skins into
                    your
                    account
                </p>
                <div v-if="products && products.length" class="product-cards">
                    <ProductCard v-for="product in products" :key="product.id" :item="product"
                        :currencySymbol="currencySymbol" :discount="discountPercentage" @goToProduct="goToProduct" />
                </div>
                <button class="market-button button" @click="goToMarket">{{ $t('Go to the market') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import ProductCard from './ProductCard.vue';

export default {
    name: 'DiscountModal',
    components: {
        ProductCard,
    },
    props: {
        isAuth: {
            type: Boolean,
            required: true
        },
        currencySymbol: {
            type: String,
            required: true
        },
        currencyCode: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            discountDuration: 0,
            discountPercentage: 0,
            products: [],
        };
    },
    async created() {
        if (!this.isAuth) return;
        try {
            this.$http.get(process.env.VUE_APP_API + 'post-purchase-discount?currency=' + this.currencyCode)
                .then((response) => {
                    const data = response.data;
                    if (data.status == 'ERROR') {
                        this.handleCloseModal();
                    } else {
                        this.$emit('openDiscountModal');
                        this.discountDuration = data.discountDuration;
                        this.discountPercentage = data.discountPercentage;
                        this.products = data.randomItems;
                    }
                })
        } catch (error) {
            // console.error('Error fetching discount data:', error);
        }
    },
    methods: {
        goToProduct(item) {
            this.$emit('goToProduct', item);
            this.$emit('closeDiscountModal');
        },
        handleCloseModal() {
            this.$emit('closeDiscountModal');
        },
        goToMarket() {
            this.$emit('closeDiscountModal');
            this.$router.push('/products/all');
        },
    },
};
</script>

<style lang="scss" scoped>
.modal.discount-modal {
    font-family: 'Quantico', sans-serif;
    color: #fff;

    ::v-deep(.item-wrapper) {
        .preview {
            width: 124px;
            height: 80px;
            margin: 0 auto;
        }

        .title-container .desc {
        }
    }

    .wrapper {
        // max-width: 790px;
        max-width: 1030px;

    }

    .container {
        max-width: 790px;
        max-height: 90vh;
        padding: 32px;
        background: #16171C;
        box-shadow: 0px 8px 8px 0px #0000001F;
        display: flex;
        flex-direction: column;
    }
}

.modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    line-height: 46px;
}

.modal-title {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.discount-message {
    text-align: center;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;

}

.highlight {
    // background-color: #6D82F2;
    color: #6D82F2;
    // padding: 2px 4px;
    // border-radius: 4px;
}

.product-cards {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    grid-template-columns: repeat(auto-fill, minmax(228px, 1fr));
    gap: 24px 18px;
    justify-content: center;
    margin-bottom: 24px;
}

.product-cards>* {
    max-width: 228px;
    width: 100%;
}

.market-button {
    width: 100%;
    padding: 13px;
    border-radius: 16px;
    line-height: 26px;

}

@media (max-width: 600px) {
    .product-cards {
        grid-template-columns: 1fr;
    }
}
</style>