<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link to="/" class="logo">
          <img src="./../assets/img/logo.svg" class="img" />
        </router-link>
      </div>
      <div class="center">
        <ul class="nav">
          <li class="nav__item">
            <router-link class="desc" to="/"><b>{{ $t('Home') }}</b></router-link>
          </li>
          <li class="nav__item">
            <router-link class="desc" to="/products/all"><b>{{ $t('Store') }}</b></router-link>
          </li>
        </ul>
      </div>
      <div class="right">
        <!-- Currency Selector -->
        <div v-if="currencyOptions.length > 1" class="select-container">
          <Select2 v-model="selectedCurrency" :options="currencyOptions" @select="handleCurrencyChange"
            class="select2 single currency-select" />
        </div>
        <!-- Language Selector -->
        <div v-if="languages.length > 1" class="select-container language-select-container">
          <Select2 v-model="selectedLanguage" :options="languageOptions" @select="handleLanguageChange"
            class="select2 single language-select" />
        </div>
        <!-- User Authentication and Cart Links -->
        <router-link v-if="isAuth" to="/cart" class="icon">
          <img src="./../assets/img/cart.svg" class="img" />
          <div v-if="cartContents.length" class="indicator">{{ cartContents.length }}</div>
        </router-link>
        <router-link v-if="isAuth" to="/profile/details" class="icon">
          <img src="./../assets/img/user.svg" class="img" />
        </router-link>
        <button v-if="!isAuth" class="button bordered" @click="openSignIn">{{ $t('Sign In') }}</button>
        <button v-if="!isAuth" class="button" @click="openSignUp">{{ $t('Sign Up') }}</button>
      </div>
    </div>
  </header>
</template>

<script>
import Select2 from 'vue3-select2-component';
import { mapState, mapActions } from "vuex";

export default {
  name: 'HeaderComponent',
  components: { Select2 },
  props: {
    isAuth: { type: Boolean, required: true },
    cartContents: { type: Array, required: true }
  },
  data() {
    return {
      selectedCurrency: null,
      selectedLanguage: null
    };
  },
  computed: {
    ...mapState('app', ['currencies', 'languages', 'currentLanguage', 'currency']),
    currencyOptions() {
      if (!this.currencies) return [];
      return this.currencies.map(({ code, symbol }) => ({
        id: code,
        text: `${code} (${symbol})`
      }));
    },
    languageOptions() {
      if (!this.languages) return [];
      return this.languages.map(({ id, title }) => ({
        id,
        text: title
      }));
    }
  },
  watch: {
    currency(newCurrency) {
      this.selectedCurrency = newCurrency?.code;
    },
    currentLanguage(newLang) {
      this.selectedLanguage = newLang;
    }
  },
  mounted() {
    this.selectedCurrency = this.currency?.code;
    this.selectedLanguage = this.currentLanguage;
  },
  methods: {
    ...mapActions('app', ['changeLocale', 'changeCurrency']),
    handleCurrencyChange({ id }) {
      this.changeCurrency(id);
    },
    handleLanguageChange({ id }) {
      this.changeLocale(id);
    },
    openSignIn() {
      this.$emit('openSignIn');
    },
    openSignUp() {
      this.$emit('openSignUp');
    }
  }
};
</script>
