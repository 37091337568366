<template>
  <main class="main cart-page page-inside" v-if="isAuth">
    <div class="main-wrapper">
      <section class="section cart-section">
        <div class="wrapper">
          <div class="title">{{$t("Cart")}}</div>
          <div class="desc empty-desc" v-if="!cartContents.length">
            Cart is empty!
          </div>
          <div class="left" v-if="cartContents.length">
            <div class="product-list">
              <div class="item" v-for="(item, i) in cartContents" :key="i">
                <div class="preview">
                  <img :src="getImageUrl(item)" class="img"/>
                </div>
                <div class="title">{{item.item.title}}</div>
                <div class="item-end">
                  <div class="count">
                    <div class="count__item minus" @click="removeOneFromCart(item)"></div>
                    <input type="number" value="1" v-model="item.count"/>
                    <div class="count__item plus" @click="addToCart(item)"></div>
                  </div>
                  <div class="price">{{item.item.price}} <span class="currency">{{ currencySymbol }}</span></div>
                </div>
                <div class="delete" @click="removeFromCart(item)">
                  <div class="desc">{{$t("Delete item")}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right" v-if="cartContents.length">
            <div class="wrapper form">
              <div class="title small">{{$t("Contact information")}}</div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t("First name")}}:</div>
                <input type="text" :placeholder="$t('First name')" v-model="name"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t("Last name")}}:</div>
                <input type="text" :placeholder="$t('Last name')" v-model="surname"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t("Email")}}:</div>
                <input type="email" :placeholder="$t('Email')" v-model="email"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t("Phone")}}:</div>
                <MazPhoneNumberInput
                  v-model="phone"
                  v-model:country-code="countryCodeName"
                  :defaultCountryCode="convertedShortName"
                  show-code-on-list
                  :only-countries="countryOptionsIso"
                  @update="phoneDataUpdate($event)"
                />
              </div>
              <div class="input-container steam-input-container">
                <div class="desc"><span class="red">*</span>{{$t("Steam ID")}}:</div>
                <input type="text" :placeholder="$t('Steam ID')" v-model="steamId"/>
              </div>
             
              <div class="input-container country-select-container">
                <div class="desc"><span class="red">*</span>{{ $t("Country") }}:</div>
                <select v-model="countryId" :class="{ 'empty': !countryId }">
                  <option value="" disabled selected>{{ $t("Country") }}</option>
                  <option v-for="option in countryOptions" :key="option.id" :value="option.id">
                    {{ option.title }}
                  </option>
                </select>
              </div>
              
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t("City")}}:</div>
                <input type="text" :placeholder="$t('City')" v-model="city"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t("Address")}}:</div>
                <input type="text" :placeholder="$t('Address')" v-model="address"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t("Post Code")}}:</div>
                <input type="text" :placeholder="$t('Post Code')" v-model="zip"/>
              </div>
              <div class="input-container">
                <div class="checkbox-container">
                  <label class="checkbox-label">
                    <div class="checkbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="terms"/>
                      <div class="checkbox"></div>
                      <span class="desc">{{$t("I agree with")}} </span>
                      <a @click="goToPage('privacy')" class="desc">{{$t("Privacy Policy")}}</a>
                      <span class="desc"> {{$t("and")}} </span>
                      <a @click="goToPage('terms')" class="desc">{{$t("Terms of use")}}</a>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="wrapper">
              <div class="row">
                <div class="desc">{{$t("Total to pay")}}:</div>
                <div class="title">{{totalSum}} <span class="currency">{{ currencySymbol }}</span></div>
              </div>
              <button @click="submit" :class="['button', {'disabled': !requiredFieldsAreFilled}]">
                <span>{{$t("Confirm")}}</span>
              </button>
              <Transition>
                <div class="desc red" v-if="error">{{error}}</div>
              </Transition>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>

export default {
  name: 'CartPage',
  props: {
    isAuth: {
      type: Boolean,
      required: true
    },
    currencySymbol: {
      type: String,
      required: true
    },
    cartContents: {
      type: Array,
      required: true
    },
    totalSum: {
      type: String,
      required: true
    },
  },
  components: {
    
  },
  data: function() {
    return {
      imgDomain: '',
      countryOptions: [],
      countryOptionsId: [],
      name: '',
      surname: '',
      email: '',
      phone: '',
      steamId: '',
      countryId: '',
      city: '',
      address: '',
      zip: '',
      terms: false,
      phoneFull: "",
      convertedShortName: '',
      countryCode: '',
      countryCodeList: [],
      profilePhone: '',
      countryOptionsIso: [],
      countryCodeName: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getProfile();
    this.getCounties();
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname && this.email && this.phone && 
        this.steamId && this.countryId && 
        this.city && this.address  
        && this.zip && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },
  },
  methods: {
    getImageUrl(item) {
      if (item.item.item_type == 'pack') {
        return item.item.img_url;
      } else {
        return this.imgDomain + item.item.img_url;
      }
    },
    goToPage(id) {
      this.$emit('goToPage', id);
    },
    getProfile() {
      let self = this;
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        if (res.status == 200) {
          self.email = res.data.email;
          self.name = res.data.name;
          self.surname = res.data.surname;
          self.phone = res.data.phone.replace(/^[^\s]*\s/, '');
          self.profilePhone = res.data.phone;
          self.address = res.data.address;
          self.city = res.data.city;
          self.zip = res.data.zip;
          self.countryId = res.data.country;
          self.steamId = res.data.steamId;
          self.getAllCountryCodes();
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn();
        }
      })
    },
    getAllCountryCodes() {
      this.$http.get(process.env.VUE_APP_API + 'phone-codes')
      .then((res) => {
        if (res.data.status == "OK") {
          this.countryCodeList = res.data.payload; 
          this.countryCode = this.getSymbolsBeforeSpace(this.profilePhone).replace( '+' ,'');
          this.getShortNameByPhoneCode(this.countryCode, this.countryCodeList);
          this.countryCodeName = this.convertedShortName;
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$emit('openSignIn');
        }
      })
    },
    getShortNameByPhoneCode(phoneCode, codeList) {
      for (let i = 0; i < codeList.length; i++) {
        const countryCode = Object.keys(codeList[i])[0];
        const codeValue = codeList[i][countryCode].code
        if (codeValue == phoneCode) {
          this.convertedShortName = countryCode;
        }
      }
    },
    getSymbolsBeforeSpace(inputString) {
      const regex = /^[^\s]+/;
      const match = inputString.match(regex);

      if (match) {
          return match[0];
      } else {
          return ""; // Return an empty string if no symbols are found
      }
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
    // selectCountry(event) {
    //   this.countryId = event.text;
    //   for (let i = 0; i < this.countryOptions.length; i++) {
    //     if (this.countryOptions[i] == event.text) {
    //       this.countryId = this.countryOptionsId[i];
    //     }
    //   }
    // },
    submit() {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "email": this.email,
        "phone": this.phoneFull,
        "steamId": this.steamId,
        "country": this.countryId,
        "city": this.city,
        "address": this.address,
        "postCode": this.zip
      }
      this.$emit('openOrderModal', data);
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
          this.countryOptionsIso = res.data.payload.map(obj => obj.iso);
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn();
        }
      })
    },
    removeFromCart(item) {
      this.$emit('removeFromCart', item, item.item_id, item.item.item_type);
    },
    removeOneFromCart(item) {
      this.$emit('removeOneFromCart', item, item.item_id, item.item.item_type);
    },
    addToCart(item) {
      this.$emit('addToCart', item.item_id, item.item.item_type)
    }
  }
}
</script>