<template>
  <div class="modal order-modal">
    <div
      class="overlay"
      @click="$emit('closeOrderModal')"
    />
    <div class="wrapper">
      <div class="container">
        <img
          src="./../assets/img/close.svg"
          @click="$emit('closeOrderModal')"
          class="close"
        />
        <div class="title small">{{ $t("Payment Method") }}</div>
        <div class="button-list-container">

          <div
            v-if="isLoading"
            class="spinner-wrapper"
          >
            <div class="dual-ring"></div>
          </div>

          <div
            class="button-list"
            v-else-if="paymentMethods && paymentMethods.length"
          >
            <button
              v-for="(item, i) in paymentMethods"
              class="img-btn"
              @click="submit(item.code)"
              :key="i"
            >
              <div class="image-wrapper">
                <img
                  :src="item.image"
                  class="img"
                />
              </div>
              <div class="payment-method-title">{{ $t(item.title) }}</div>
            </button>
          </div>

          <div
            class="desc"
            v-else-if="!isLoading && !paymentMethods.length && !error"
          >
            {{ $t("There are no payment methods available") }}
          </div>

          <transition name="fade">
            <div
              class="desc red"
              v-if="error || this.$parent.error"
            >{{ error || this.$parent.error }}</div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'OrderModal',
  props: {
    error: {
      type: String,
      required: true
    },
    orderData: {
      type: Object,
      required: true
    },
    orderModalIsVisible: {
      type: Boolean,
    }
  },
  data: function () {
    return {
      isLoading: false,
      availablePaymentMethods: [],
      modalError: '',
    }
  },
  computed: {
    ...mapGetters('app', ['currencyCode']),
    paymentMethods() {
      return this.availablePaymentMethods;
    },
  },
  watch: {
    orderModalIsVisible() {
      if (this.orderModalIsVisible) {
        this.fetchPaymentMethods(this.orderData.country, this.currencyCode);
      }
    },
  },
  methods: {
    fetchPaymentMethods(countryId, currencyCode) {
      this.isLoading = true;
      this.modalError = '';
      this.availablePaymentMethods = [];

      this.$http.post(process.env.VUE_APP_API + 'orders/payment-methods', {
        country: countryId,
        currency: currencyCode,
      })
        .then(response => {
          if (response.data.status === 'OK') {
            this.availablePaymentMethods = response.data.payload;
          } else {
            this.modalError = response.data.message;
          }
        })
        .catch(error => {
          this.modalError = error.response.data.message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    submit(type) {
      this.$emit('orderSubmit', this.orderData, type)
    },
  },
  mounted() {
    if (this.orderModalIsVisible) {
      this.fetchPaymentMethods(this?.orderData?.country, this.currencyCode);
    }
  }
}
</script>
<style lang="scss" scoped>
.order-modal .wrapper .container {
  max-height: calc(100vh - 32px);
  display: block;
  overflow-y: auto;
}

.order-modal {
  z-index: 1100;
  padding: 16px;

  .wrapper {
    max-width: 1300px;
  }

  .button {
    width: 190px;
    margin: 0;
  }

  .img-btn {
    line-height: 0;
    padding: 0;
    background: transparent;
    flex: 1;
    align-items: center;
    display: flex;
    gap: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    text-align: left;
    padding: 6px 16px 6px 6px;
    transition: .3s;
    border-radius: 24px;

    .img {
      height: 100%;
    }

    &:hover {
      background: #1C1A24;
    }
  }

  .button-list-container {
    padding: 50px;

    &>.desc {
      text-align: center;
    }
  }

  .image-wrapper {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 24px;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      padding: 10px;
    }
  }

  .button-list {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 10px 20px;
    grid-template-columns: repeat(auto-fill, minmax(calc(min(311px, 100%)), 1fr));


    .payment-method-title {
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
      overflow: hidden;
    }

  }

  .title {
    text-align: center;
    padding-top: 50px;
  }

}


.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}


.dual-ring,
.dual-ring:after {
  box-sizing: border-box;
}

.dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6.4px solid white;
  border-color: white transparent white transparent;
  animation: dual-ring 1.2s linear infinite;
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .order-modal {
    .button-list-container {
      padding: 20px 0 0 0;
    }
  }
}
</style>