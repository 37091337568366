<template>
  <main class="main page-inside text-page">
    <div class="section text-section">
      <div class="page-inside-top">
        <Transition>
          <div 
            v-if="textPageTitle"
            class="wrapper" 
          >
            <div class="title">
              {{ textPageTitle }}
            </div>
            <div 
              class="desc" 
              v-html="textPageHeader"
            />
            <div 
              class="desc"  
              v-html="textPageContent"
            />
          </div>
        </Transition>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'TextPage',
  components: {

  },
  props: {
    textPageTitle: {
			type: String,
			required: true
		},
    textPageHeader: {
			type: String,
			required: true
		},
    textPageContent: {
			type: String,
			required: true
		}
  },
  data: function() {
    return {
    }
  },
  mounted() {
    this.$emit('getTextPageContent', this.$route.params.id);
  },
  methods: {
    
  }
}
</script>