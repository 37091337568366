<template>
  <main class="main page-inside payment-result-page">
    <div class="section payment-section">
      <div class="page-inside-top">
        <div class="wrapper">
          <div class="title big">
            {{ $t(`Thank you - your payment was successful. You will shortly receive a confirmation to your e-mail. In
            case of any questions, please contact us at `) }} {{ this.footerSupportEmail }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'PaymentSuccessPage',
  components: {
  },
  props: [
    'footerSupportEmail',
  ],
  created() {
  },
  data: function () {
    return {

    }
  },
  methods: {

  },
  mounted() {
    this.$emit('paymentSuccess');
  }
}
</script>