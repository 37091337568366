<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <section class="section product-list-section">
        <div class="wrapper">

          <div class="title">
            {{ $t(`Store`) }}
          </div>

          <div class="filters-container">
            <div class="filters-text">Filters</div>

            <!-- Price Filter -->
            <div
              class="filter-dropdown price-filter"
              ref="priceDropdownRef"
              @click="toggleDropdown('priceListIsVisible')"
            >
              <span>Price {{ barMinValue }} - {{ barMaxValue }}</span>

              <img
                src="@/assets/img/arrow-down.svg"
                :class="{ 'rotated': priceListIsVisible }"
                alt="arrow"
              />
              <div
                v-if="priceListIsVisible"
                class="dropdown-menu price-dropdown"
                @click="stopPropagation"
              >
                <div class="price-input-container">
                  <label class="price-label">
                    <span class="min">Min:</span>
                    <input
                      type="number"
                      v-model="barMinValue"
                      class="price-input"
                      placeholder="0"
                    />
                  </label>
                  <input
                    type="range"
                    v-model="barMinValue"
                    min="0"
                    max="10000"
                  />
                  <label class="price-label">
                    <span class="max">Max:</span>
                    <input
                      type="number"
                      v-model="barMaxValue"
                      class="price-input"
                      placeholder="10000"
                    />
                  </label>
                  <input
                    type="range"
                    v-model="barMaxValue"
                    min="0"
                    max="10000"
                  />
                </div>
              </div>
            </div>

            <!-- Type Filter -->
            <div
              class="filter-dropdown type-filter"
              ref="typeDropdownRef"
              @click="toggleDropdown('typeListIsVisible')"
            >
              <div>
                <div class="filter-label">Type</div>
                <div class="filter-value">{{ category ? category : 'All' }}</div>
              </div>
              <img
                src="@/assets/img/arrow-down.svg"
                :class="{ 'rotated': typeListIsVisible }"
                alt="arrow"
              />
              <div
                v-if="typeListIsVisible"
                class="dropdown-menu"
                @click="stopPropagation"
              >
                <label
                  v-for="(item) in categoryOptions"
                  :key="item"
                  class="checkbox-label"
                >
                  <div class="checkbox-label-wrapper">
                    <input
                      type="radio"
                      :value="item"
                      v-model="category"
                    />
                    <div class="checkbox"></div>
                    <span class="desc">{{ item }}</span>
                  </div>
                </label>
              </div>
            </div>

            <!-- Quality Filter -->
            <div
              class="filter-dropdown quality-filter"
              ref="qualityDropdownRef"
              @click="toggleDropdown('qualityListIsVisible')"
            >
              <div>
                <div class="filter-label">Quality</div>
                <div class="filter-value">{{ quality ? quality : 'All' }}</div>
              </div>
              <img
                src="@/assets/img/arrow-down.svg"
                :class="{ 'rotated': qualityListIsVisible }"
                alt="arrow"
              />
              <div
                v-if="qualityListIsVisible"
                class="dropdown-menu"
                @click="stopPropagation"
              >
                <label
                  v-for="(item) in qualityOptions"
                  :key="item"
                  class="checkbox-label"
                >
                  <div class="checkbox-label-wrapper">
                    <input
                      type="radio"
                      :value="item"
                      v-model="quality"
                    />
                    <div class="checkbox"></div>
                    <span class="desc">{{ item }}</span>
                  </div>
                </label>
              </div>
            </div>

            <!-- Sort -->
            <div class="sort-container">
              <span class="sort-text">Sort:</span>
              <div
                class="filter-dropdown sort-dropdown"
                ref="sortDropdownRef"
                @click="toggleDropdown('sortListIsVisible')"
              >
                <div>
                  <div class="filter-label">Sort by</div>
                  <div class="filter-value">{{ sort === 'desc' ? 'Highest price' : 'Lowest price' }}</div>
                </div>
                <img
                  src="@/assets/img/arrow-down.svg"
                  :class="{ 'rotated': sortListIsVisible }"
                  alt="arrow"
                />
                <div
                  v-if="sortListIsVisible"
                  class="dropdown-menu"
                  @click="stopPropagation"
                >
                  <label class="checkbox-label">
                    <div class="checkbox-label-wrapper">
                      <input
                        type="radio"
                        name="desc"
                        value="desc"
                        v-model="sort"
                      />
                      <div class="checkbox"></div>
                      <span class="desc">{{ $t("Highest price first") }}</span>
                    </div>
                  </label>
                  <label class="checkbox-label">
                    <div class="checkbox-label-wrapper">
                      <input
                        type="radio"
                        name="asc"
                        value="asc"
                        v-model="sort"
                      />
                      <div class="checkbox"></div>
                      <span class="desc">{{ $t("Lowest price first") }}</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div :class="['products-container', { active: !filtersIsVisible }]">


            <div
              v-if="isFetchingProducts"
              class="loading-state"
            >
              {{ $t(`Loading...`) }}
            </div>

            <Transition v-if="productList && productList.length > 0">
              <div class="list products">
                <div
                  class="item"
                  v-for="(item, i) in productList"
                  :key="i"
                >
                  <ProductCard
                    :item="item"
                    :currencySymbol="currencySymbol"
                    @goToProduct="goToProduct"
                  />
                </div>
              </div>
            </Transition>

            <div
              v-else-if="productList && productList.length === 0 && !isFetchingProducts"
              class="no-items-message"
            >
              {{ $t("No items found.") }}
              <button
                @click="resetFilters"
                class="button reset-filters-button"
              >
                {{ $t("Reset Filters") }}
              </button>
            </div>

          </div>
          <Pagination
            v-if="totalProducts"
            :total-pages="totalPages"
            v-model:per-page="perPage"
            v-model:current-page="activePage"
            @update:per-page="updatePerPage"
            @update:current-page="changePage"
          />
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import { mapActions } from 'vuex';
import { debounce } from 'lodash';
import { onClickOutside } from '@vueuse/core';
import ProductCard from "../components/ProductCard.vue";
import Pagination from '../components/PaginationComponent.vue';
export default {
  name: "ProductListPage",

  props: {
    isAuth: {
      type: Boolean
    },
    currencyCode: {
      type: String,
      required: true
    },
    currencySymbol: {
      type: String,
      required: true
    },
  },
  components: {
    Pagination,
    ProductCard,
  },
  data: function () {
    return {
      isFetchingProducts: true,
      imgDomain: "",
      sort: "desc",
      category: "All",
      quality: "All",
      categoryOptions: {},
      qualityOptions: {},
      barMinValue: 0,
      barMaxValue: 10000,
      productList: {},

      perPage: 20,
      activePage: 1,
      totalProducts: null,
      totalPages: 1,

      filtersIsVisible: true,
      priceListIsVisible: false,
      typeListIsVisible: false,
      qualityListIsVisible: false,
      sortListIsVisible: false,

      priceDropdownRef: null,
      typeDropdownRef: null,
      qualityDropdownRef: null,
      sortDropdownRef: null,

      debounceFilterProducts: debounce(this.filterProducts, 300),

      specialCategory: "",
    };
  },
  watch: {
    currencyCode: function () {
      this.debounceFilterProducts();
    },
    barMinValue: function () {
      this.activePage = 1;
      this.debounceFilterProducts();
    },
    barMaxValue: function () {
      this.activePage = 1;
      this.debounceFilterProducts();
    },
    sort() {
      this.activePage = 1;
      this.debounceFilterProducts();
    },
    quality() {
      this.activePage = 1;
      this.debounceFilterProducts();
    },
    category() {
      this.activePage = 1;
      this.debounceFilterProducts();
    },
    $route() {
      this.activePage = 1;
      this.checkRouteParams();
    },
  },

  mounted() {
    this.checkRouteParams();
    this.getCategoryOptions();
    this.getQualityOptions();
    if (this.isAuth) {
      this.fetchActiveDiscount(this.currencyCode);
    }

    onClickOutside(this.$refs.priceDropdownRef, () => this.priceListIsVisible = false);
    onClickOutside(this.$refs.typeDropdownRef, () => this.typeListIsVisible = false);
    onClickOutside(this.$refs.qualityDropdownRef, () => this.qualityListIsVisible = false);
    onClickOutside(this.$refs.sortDropdownRef, () => this.sortListIsVisible = false);
  },
  beforeUnmount() {
  },
  methods: {
    ...mapActions('discount', ['fetchActiveDiscount']),
    stopPropagation(event) {
      event.stopPropagation();
    },
    toggleDropdown(dropdown) {
      this[dropdown] = !this[dropdown];
      ['priceListIsVisible', 'typeListIsVisible', 'qualityListIsVisible', 'sortListIsVisible'].forEach(key => {
        if (key !== dropdown) {
          this[key] = false;
        }
      });
    },
    checkRouteParams() {
      if (this.$route.params.type == "revolution-collection") {
        this.specialCategory = this.$route.params.type;
      } else if (this.$route.params.type && this.$route.params.type != "all") {
        this.category = this.$route.params.type;
        this.specialCategory = "";
      } else if (this.$route.params.type && this.$route.params.type === "all") {
        this.category = "All";
        this.specialCategory = "";
      }
      this.activePage = 1;
      this.filterProducts();
    },
    resetFilters() {
      this.quality = "All";
      this.category = "All";
      this.barMinValue = 0;
      this.barMaxValue = 10000;
    },
    openFilter() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    filterProducts() {
      this.isFetchingProducts = true;
      let addCategory = "";
      if (this.category != "All") {
        addCategory = "type=" + this.category;
      } else {
        addCategory = "type=";
      }

      let addQuality = "";
      if (this.quality != "All") {
        addQuality = "&quality=" + this.quality;
      } else {
        addQuality = "";
      }
      let addPrice = "&price_from=" + this.barMinValue + "&price_till=" + this.barMaxValue;

      let addSort = "&sort=" + this.sort;

      let addPage = "&page=" + this.activePage;

      this.$http
        .get(process.env.VUE_APP_API + "items/list?" + addCategory + addQuality + addPrice + addSort + addPage + `&limit=${this.perPage}` + "&category=" + this.specialCategory + '&currency=' + this.currencyCode)
        .then((res) => {
          this.productList = res.data.payload;
          this.totalProducts = res.data.meta.total;
          this.totalPages = res.data.meta.pages;
        })
        .catch(() => {
        })
        .finally(() => {
          this.isFetchingProducts = false;
        });
    },
    changePage(page) {
      this.activePage = page;
      this.debounceFilterProducts();
    },
    updatePerPage(newPerPage) {
      this.perPage = newPerPage;
      this.activePage = 1;
      this.filterProducts();
    },
    getCategoryOptions() {
      this.$http
        .get(process.env.VUE_APP_API + "items/filter-data")
        .then((res) => {
          let newCategories = res.data.payload.types;
          newCategories.unshift("All", "cases");
          this.categoryOptions = newCategories;
        })
        .catch(() => {
        });
    },
    getQualityOptions() {
      this.$http
        .get(process.env.VUE_APP_API + "items/filter-data")
        .then((res) => {
          let newQuality = res.data.payload.qualities;
          newQuality.unshift("All");
          this.qualityOptions = newQuality;
        })
        .catch(() => {
        });
    },
    addToCart(item) {
      this.$emit('addToCart', item.item_id, item.item.item_type)
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
  },
};
</script>
<style scoped lang="scss">
.product-list-section .title {
  margin-bottom: 40px;
}

.filters-container {
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
}

.filters-text,
.sort-text {
  font-family: Quantico, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 28.6px;
  text-align: left;
  margin-right: 16px;
}

.filter-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  border-radius: 16px;
  border: 1px solid #6d7a94;
  background: #20222F;

  cursor: pointer;
  margin-right: 16px;
  max-width: 234px;
  width: 100%;
  height: 56px;
  position: relative;

  &.price-filter {
    color: #a8b2bd;
  }

  &.sort-dropdown {
    margin-right: 0;
  }

  .filter-label {
    font-family: Quantico, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #a8b2bd;
  }

  .filter-value {
    font-family: Quantico, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  img {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;

    &.rotated {
      transform: rotate(180deg);
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #20222f;
    padding: 16px;
    border: 1px solid #6d7a94;
    border-radius: 16px;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .price-dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .price-input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;

    .price-label {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }

    .price-label .min,
    .price-label .max {
      width: 40px;
      display: inline-block;
    }

  }

  .price-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #6d7a94;
    border-radius: 8px;
    background-color: #2a2d3a;
    color: white;
  }

  input[type="range"] {
    width: 100%;
    padding: 0;
    margin-top: 8px;
    background: inherit;
  }
}

.sort-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.no-items-message {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  color: white;
  margin: 30px auto;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .filters-container {
    flex-wrap: wrap;
  }

  .filters-text,
  .sort-text {
    align-self: flex-start;
    margin-bottom: 16px;
  }

  .sort-container {
    flex-direction: column;
  }

  .filter-dropdown,
  .sort-container {
    margin-bottom: 16px;
    width: 100%;
    min-width: 100%;
  }

  .products-container {
    // max-height: 543px;
    // overflow-y: auto;
  }
}
</style>